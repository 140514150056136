<template>
    <div>
        <posts/>
    </div>
</template>

<script>

import posts from '@/components/home/posts'

export default {
    name: "Home",
    components: { posts }

};
</script>
